.title
  margin: 0
  padding: 0
  color: $dark
  font-size: 2em
  font-weight: 700
  +v-sm
    margin: 0 0 16px 8px
  &-mini
    font-size: 0.85em
  &-cta
    font-size: 3em
    font-weight: 600
    line-height: 1.6
    +v-sm
      font-size: 1.65em
      font-weight: 400
      line-height: 1.4
    +v-xs
      font-size: 1.15em
      line-height: 1.25
  &-intro
    color: $theme
    font-size: 4.2em
    font-weight: 600
    +v-sm
      zoom: .85
    +v-sm
      zoom: .85
    &-sub
      color: $dark
      font-size: 2em
      font-weight: 400
      line-height: 1.6
    &-cta
      font-size: 2em
      font-weight: 700
  &-sub
    color: $dark
    font-size: 1.25em
    font-weight: 400
    +v-xs
      font-size: .9em
      margin-left: 3%
      margin-right: 3%
