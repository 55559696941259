@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')

$body: #fff
$theme: #02BEF5
$dark: #282828
$white: #fff
$light: #C6C6C6
$medium: #4E4F51
$lite: #F5F4F3

$button: $theme

$blue: #0057B7
$violet: #8E4A95
$darkblue: #3f436c
$pink: #EA4E98
$marine: #00AACB
$footer: #44727B
$grey: #cdcdcd
$green: #99b660

$basic: 'Poppins', sans-serif
$intro: 'Poppins', sans-serif

=placeholder
  &::-webkit-input-placeholder
    @content
  &:-moz-placeholder
    @content
  &::-moz-placeholder
    @content
  &:-ms-input-placeholder
    @content
