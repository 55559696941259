html
  font-family: $basic
  font-weight: normal
  font-size: 1rem
  font-display: auto

body
  width: 100%
  max-width: 100% !important
  padding-top: 80px
  color: $light
  font-size: 95%
  font-family: $basic
  font-weight: 400
  font-smooth: always
  letter-spacing: .03em
  line-height: 1.6
  -webkit-font-smoothing: antialised
  -moz-osx-font-smoothing: grayscale
  overflow-x: hidden
  background-color: $body
  &.menu-active
    height: 100vh
    overflow: hidden

.mob-button
  +v-sm
    position: fixed
    right: 80px
    top: 16px

a, * a
  color: inherit !important
  text-decoration: none !important

.col-lg-*
  +v-sm
    margin-top: 10px
    margin-bottom: 10px


#results,
#about,
#donations
  *
    +v-sm
      text-align: center !important

#top
  +v-sm
    *
      text-align: center !important
  img
    display: block
    width: 100%
    height: auto
    object-fit: cover
    border-radius: 40px

.card
  &-res
    width: 100%
    height: 100%
    padding: 32px 24px
    background-color: #fff
    border-radius: 24px
    box-shadow: 0 8px 28px -6px rgba(24, 39, 75, .12), 0 18px 88px -4px rgba(24, 39, 75, .14)
    &.noactive
      padding: 24px 32px
      box-shadow: none
      background-color: $lite
      &:hover
        box-shadow: 0 8px 28px -6px rgba(24, 39, 75, .12), 0 18px 88px -4px rgba(24, 39, 75, .14)


footer
  display: block
  border-top: 2px solid $lite
  +v-sm
    display: block
    padding: 40px 20px !important
    height: auto
    min-height: auto !important
    text-align: center !important
  img
    transition: all ease-out .3s
    &:hover
      opacity: .5



img
  +v-sm
    width: auto !important
    max-width: 100% !important
    margin-left: auto !important
    margin-right: auto !important

img
  &.mobile-pic
    +v-sm
      width: 238px !important
      max-width: 238px !important
      height: 112px !important
      margin: 20px auto !important

.m-30
  display: none
  +v-sm
    display: block
    width: 100%
    height: 30px
.m-20
  display: none
  +v-sm
    display: block
    width: 100%
    height: 20px
